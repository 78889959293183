import React from 'react'
import { Box } from '@xstyled/styled-components'
import Page from '@/components/Page'
import PageWrapper from '@/components/PageWrapper'
import { Text } from '@/components/Text'
import WithSlideShowCheck from '@/components/Helpers/WithSlideShowCheck'
import { pageFadeInOut } from '@/modules/animationVariants'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const ManifestPage: React.FC = () => {
  const { t } = useTranslation()

  const content = {
    pretitle: t('dna.manifest_super', 'fill copy key: dna.manifest_super'),
    title: t('dna.manifest_title', 'fill copy key: dna.manifest_title'),
    text: t('dna.manifest_body', 'fill copy key: dna.manifest_body'),
    links: [
      {
        link: t('dna.manifest_cta', 'fill copy key: dna.manifest_cta'),
        url: '/our-dna/first',
        variant: 'all',
      },
    ],
  }

  return (
    <>
      <Page
        content={
          <PageWrapper>
            <WithSlideShowCheck
              mobileContent={[<Text isMobile {...content} />]}
            >
              <Box row>
                <Box col={0.4}>
                  <Text {...content} />
                </Box>
                <Box col={0.6} />
              </Box>
            </WithSlideShowCheck>
          </PageWrapper>
        }
        settings={{
          backgroundColor: '#113f4f',
          contentAnimationVariants: pageFadeInOut(),
          useCircularWipe: false,
        }}
      />
    </>
  )
}
export default ManifestPage
